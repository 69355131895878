<template>
  <Layout>
    <template v-if="this.$route.name == 'add-gift-card'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label>Company <span style="color: red">*</span></label>
                <multiselect
                  @search-change="fetchCompanyList"
                  id="exp"
                  v-model="form.gift_company_id"
                  :options="getCompanyList"
                  :multiple="false"
                  track-by="id"
                  label="name"
                  placeholder="Type here to search"
                  :class="{
                    'is-invalid': submitted && $v.form.gift_company_id.$error,
                  }"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
                <div
                  v-if="submitted && !$v.form.gift_company_id.required"
                  class="invalid-feedback"
                >
                  Gift Company is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                label-for="input-4"
                class="col-6"
              >
                <label>Value <span style="color: red">*</span></label>
                <b-form-input
                  id="input-4"
                  placeholder="Enter Value"
                  v-model="form.value"
                  type="number"
                  :class="{
                    'is-invalid': submitted && $v.form.value.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.value.required"
                  class="invalid-feedback"
                >
                  Value is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                label-for="input-4"
                class="col-6"
              >
                <label>Coupon Count <span style="color: red">*</span></label>
                <b-form-input
                  id="input-4"
                  placeholder="Enter Coupon Count"
                  v-model="form.coupon_count"
                  type="number"
                  :class="{
                    'is-invalid': submitted && $v.form.coupon_count.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.coupon_count.required"
                  class="invalid-feedback"
                >
                  Coupon Count is required.
                </div>
              </b-form-group>
              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                  ><span v-if="this.$route.name == 'add-gift-card'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import giftMixin from "../../../../mixins/ModuleJs/gift-card";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  mixins: [MixinRequest, giftMixin],
  data() {
    return {
      title1: "ADD GIFT CARD",
      title2: "EDIT GIFT CARD",
      items: [
        {
          text: "Back",
          href: "/gift-card",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  validations: {
    form: {
      gift_company_id: { required },
      value: { required },
      coupon_count: { required },
    },
  },
};
</script>
